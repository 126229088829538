import React, { useState } from "react"
import quoteleft from "../images/quote-left-solid.svg"
import ReactInterval from 'react-interval';

const reviews = [
  {
    text: 'Joe and his team did an amazing job replacing our roof! They were fast and professional. From the quality of the work and materials, their efficiency in getting our roof done on time, responsiveness down to the clean up everything was well worth a five star review. They even reroofed our doghouse with the extra shingles! Thank you Joe for all y’all’s hard work! We truly appreciate it! Highly recommend!',
    name: 'Kristen Fenley'
  },
  {
    text: 'Joe and his guys put a roof on my parent\'s place, and two different co workers of mine houses. All three roofs done professionally and in timely manner at very competitive rate. All three where real high quality work, coming from three different people. He also gave my brother in law a shot a job when he really needed one. Very great business and would highly recommend.',
    name: 'John Allen'
  },
  {
    text: 'I would highly recommend this company to anyone looking for roof replacement.  Joe was quick to provide an estimate & I chose him for the job because of his promise to do it right, his honesty & professionalism. Him & his crew did a fantastic job. Thanks Joe!!',
    name: 'Debbie Ward'
  },
  {
    text: 'These guys went above and beyond on our roof. Couldn\'t ask for any better. Joe is a solid guy and went the extra mile on all he did. Cannot go wrong with Rayburns Roofing.',
    name: 'Hayden Trow'
  },
  {
    text: 'Joe and his team did a great job on our storage shed roofing project. They were professional and always on time. We highly recommend Rayburn’s Roofing and will use them again for future projects. Thank you Joe!',
    name: 'Bryan Corley'
  }
];

const Testimonials = () => {
  const [review, setReview] = useState(0);
  const [intervalEnabled, setIntervalEnabled] = useState(true);
  return (
    <div class="py-12 bg-white" id="testimonials">
      <ReactInterval timeout={5000} enabled={intervalEnabled}
          callback={() => setReview(review === reviews.length - 1 ? 0 : review + 1)} />
      <div class="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
        <div class="lg:text-center">
          <p class="text-base leading-6 text-red-600 font-semibold tracking-wide uppercase">Testimonials
          </p>
          <h3 class="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
            What our <span class="text-red-600">customers</span> are saying
          </h3>
          <p class="mt-4 max-w-2xl text-xl leading-7 text-gray-500 lg:mx-auto">
            If you don't believe that we're the best in the business, read some of the testimonials that previous satisfied customers have left us. We're sure you'll like what you see.
          </p> 
          <br />
          <img alt='View Testimonials from satisfied customers who trusted Rayburns Roofing' class="opacity-25" height="150" width="150" src={quoteleft} />
          <Testimonial
            text={reviews[review].text}
            name={reviews[review].name}
          />
          <br />
          <br />
          <button onClick={() => {
            if (review === 0) {
              setReview(reviews.length - 1);
              setIntervalEnabled(false);
            } else {
              setReview(review - 1);
              setIntervalEnabled(false);
            }
          }} class="bg-red-300 hover:bg-red-400 text-red-800 font-bold py-2 px-4 rounded-l">
              &larr;
          </button>
          <button onClick={() => {
            setReview(review === reviews.length - 1 ? 0 : review + 1);
            setIntervalEnabled(false);
          }} class="bg-red-300 hover:bg-red-400 text-red-800 font-bold py-2 px-4 rounded-r">
            &rarr;
          </button>
          <div class="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-center">

          <div class="rounded-md shadow">
                  <a href="https://www.google.com/search?q=rayburns+roofing&rlz=1C5CHFA_enUS901US901&oq=rayburns+roofing&aqs=chrome.0.69i59l3j0l2j69i60l3.3351j0j7&sourceid=chrome&ie=UTF-8#lrd=0x8638a75b1c3dbec1:0x433217217dc153d8,3,,," class="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-red-600 hover:bg-red-500 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10">
                    Leave a Review
                  </a>
                </div>
                <div class="mt-3 sm:mt-0 sm:ml-3">
                  <a href="https://www.google.com/search?q=rayburns+roofing&rlz=1C5CHFA_enUS901US901&oq=rayburns+roofing&aqs=chrome.0.69i59l3j0l2j69i60l3.3351j0j7&sourceid=chrome&ie=UTF-8#lrd=0x8638a75b1c3dbec1:0x433217217dc153d8,1,,," class="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-red-700 bg-red-100 hover:text-red-600 hover:bg-red-50 focus:outline-none focus:shadow-outline focus:border-red-300 transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10">
                    View More
                  </a>
                </div>
                </div>
        </div>
      </div>
    </div>
  );
};

const Testimonial = ({ text, name }) => {
  return (
    <div>
      <p style={{ marginTop: -100 }}>{text}</p>
      <hr style={{
        width: '10%',
        marginTop: 20,
        marginLeft: 'auto',
        marginRight: 'auto',
        marginBottom: 20 }}
      />
      <strong>{ name }</strong>
      <div class="rating">
        {[1, 2, 3, 4, 5].map(x => {
          return <span style={{color: 'gold'}}>☆</span>
        })}
      </div>
    </div>
  );
};

export default Testimonials