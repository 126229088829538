import React from "react"
import coupon from '../images/coupon.jpg'
const CouponHard = () => {
    const [couponExpanded, setCouponExpanded] = React.useState(false);

    return (
        <>
            {couponExpanded &&
                <>
                    <div style={{ zIndex: '99 !important' }} id="myModal" class="modal">
                      <div class="modal-content">
                        <span onClick={() => setCouponExpanded(false)} class="close">&times;</span>
                        <img src={coupon} />
                      </div>
                    </div>
                </>
            }
            <div class="bg-red-900 text-center py-4 lg:px-4">
                <div class="p-2 bg-red-800 items-center text-red-100 leading-none lg:rounded-full flex lg:inline-flex" role="alert">
                    <span onClick={() => setCouponExpanded(true)} style={{ cursor: 'pointer' }} class="flex rounded-full bg-red-500 uppercase px-2 py-1 text-xs font-bold mr-3">$250 Off</span>
                    <span class="font-semibold mr-2 text-left flex-auto">Any Roofing/Repair Services over $2,500</span>
                </div>
            </div>
        </>
        )
}
export default CouponHard