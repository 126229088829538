import React from "react"
import Certainteed from "../images/CERTAINTEED_LOGO.svg"
import GAF from "../images/GAF_LOGO.svg"
import OC from "../images/OC_LOGO.svg"
import TAMKO from "../images/TAMKO_LOGO.svg"
const Brands = () => (
<div class="py-12 bg-white">
  <div class="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
    <div class="lg:text-center">
      <p class="text-base leading-6 text-red-600 font-semibold tracking-wide uppercase">Brands we trust
      </p>
      <h3 class="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
        Only the <span class="text-red-600">best</span>
      </h3>
      <p class="mt-4 max-w-2xl text-xl leading-7 text-gray-500 lg:mx-auto">
        We know that quality matters. High quality work is nothing without using high quality materials. We trust some of the top brands in the industry.
      </p>
    </div>
  </div>
  <div class="lg:text-center ml-auto mr-auto w-2/3 md:grid md:grid-cols-4 mt-10">
    <img alt='Rayburns Roofing Trusts Certainteed' class="self-centered w-1/2 mx-auto h-24" src={Certainteed} />
    <img alt='Rayburns Roofing Trusts Tamko' class="self-centered w-1/2 mx-auto h-24" src={TAMKO} />
    <img alt='Rayburns Roofing Trusts OC' class="self-centered w-1/2 mx-auto h-24" src={OC} />
    <img alt='Rayburns Roofing Trusts Gaf' class="self-centered w-1/2 mx-auto h-24" src={GAF} />
  </div>
</div>
)
export default Brands