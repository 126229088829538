import React from "react"
import hero from "../images/hero-image.jpg"
const Hero = () => (
<div class="relative bg-white overflow-hidden">
  <div class="max-w-screen-xl mx-auto bg-white">
    <div style={{ zIndex: 1 }} class="relative pb-8 bg-white sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32 bg-white">
      <svg class="hidden lg:block absolute right-0 inset-y-0 h-full w-48 text-white transform translate-x-1/2" fill="#fff" viewBox="0 0 100 100" preserveAspectRatio="none">
        <polygon points="50,0 100,0 50,100 0,100" />
      </svg>

      <div class="mt-5 mx-auto max-w-none px-4 bg-white">
        <div class="sm:text-center lg:text-left">
          <h2 class="text-4xl tracking-tight leading-10 font-extrabold text-gray-900 sm:text-5xl sm:leading-none md:text-6xl">
            Combining
            <br class="xl:hidden" />
            <span class="text-red-600"> quality roofing &nbsp;
            </span>
            and
            <span class="text-red-600"> competitive prices
            </span>
          </h2>
          <p class="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
          Rayburn’s Roofing is a Cleveland, Texas based company that provides quality roofing at a competitive price to customers in and around the Greater Houston area. Services provided by our company are tailored to both the needs and satisfaction of the customer. We strive to build long-term trust with our customers by consistently using top-notch materials with the utmost quality and care. Our goal is to keep your family and business safe, which is why we also offer emergency storm damage repair. Commercial or residential, if you need roofing work done- contact us today to see how we can serve you.
          </p>
          <div class="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
            <div class="rounded-md shadow">
              <a href="mailto:rayburnroofing@gmail.com?subject=Website Inquiry" class="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-red-600 hover:bg-red-500 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10">
                Get Quote
              </a>
            </div>
            <div class="mt-3 sm:mt-0 sm:ml-3">
              <a href="tel:713-865-6014" class="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-red-700 bg-red-100 hover:text-red-600 hover:bg-red-50 focus:outline-none focus:shadow-outline focus:border-red-300 transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10">
                Call Now
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="mt-3 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
    <img id='heroImg' class="mt-3 h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full" src={hero} alt="Rayburn's Roofing - Quality Roofing Near You" />
  </div>
</div>
)
export default Hero