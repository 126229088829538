import React from "react"
const Services = () => (
<div class="py-12 bg-white" id="services">
  <div class="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
    <div class="lg:text-center">
      <p class="text-base leading-6 text-red-600 font-semibold tracking-wide uppercase">Services
      </p>
      <h3 class="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
        How can we serve <span class="text-red-600">you</span>?
      </h3>
      <p class="pt-4 max-w-2xl text-xl leading-7 text-gray-500 lg:mx-auto">
        We offer a wide range of both residential and commercial services, including new roofs, re-roofs, repairs, gutters, and more. Click on the section that suits your needs below for more information.
      </p>
    </div>

    <div class="mt-10">
      <ul class="mb-10 md:grid md:grid-cols-2 md:col-gap-8 md:row-gap-10">
        <a href='/residential-services' class="rounded-md hover:bg-gray-200 p-5">
          <li>
            <div class="flex">
              <div class="flex-shrink-0">
                <div class="flex items-center justify-center h-12 w-12 rounded-md bg-red-500 text-white">
                <svg class="h-6 w-6" stroke="currentColor" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path class="heroicon-ui" d="M13 20v-5h-2v5a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2v-7.59l-.3.3a1 1 0 1 1-1.4-1.42l9-9a1 1 0 0 1 1.4 0l9 9a1 1 0 0 1-1.4 1.42l-.3-.3V20a2 2 0 0 1-2 2h-3a2 2 0 0 1-2-2zm5 0v-9.59l-6-6-6 6V20h3v-5c0-1.1.9-2 2-2h2a2 2 0 0 1 2 2v5h3z"/></svg>
                </div>
              </div>
            <div class="ml-4">
                <h4 class="text-lg leading-6 font-medium text-gray-900">Residential Services
                </h4>
                <p class="mt-2 text-base leading-6 text-gray-500">
                  Our team of contractors have a minimum of 20 years experience, so you can trust Rayburn’s Roofing for the highest quality roofing
                </p>
              </div>
            </div>
          </li>
        </a>
        <a href='/commercial-services' class="rounded-md hover:bg-gray-200 p-5">
          <li class="mt-10 md:mt-0">
            <div class="flex">
              <div class="flex-shrink-0">
                <div class="flex items-center justify-center h-12 w-12 rounded-md bg-red-500 text-white">
                  <svg class="h-6 w-6" stroke="currentColor" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path class="heroicon-ui" d="M20 11.46V20a2 2 0 0 1-2 2h-3a2 2 0 0 1-2-2v-4h-2v4a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2v-8.54A4 4 0 0 1 2 8V7a1 1 0 0 1 .1-.45l2-4A1 1 0 0 1 5 2h14a1 1 0 0 1 .9.55l2 4c.06.14.1.3.1.45v1a4 4 0 0 1-2 3.46zM18 12c-1.2 0-2.27-.52-3-1.35a3.99 3.99 0 0 1-6 0A3.99 3.99 0 0 1 6 12v8h3v-4c0-1.1.9-2 2-2h2a2 2 0 0 1 2 2v4h3v-8zm2-4h-4a2 2 0 1 0 4 0zm-6 0h-4a2 2 0 1 0 4 0zM8 8H4a2 2 0 1 0 4 0zm11.38-2l-1-2H5.62l-1 2h14.76z"/></svg>
                </div>
              </div>
              <div class="ml-4">
                <h4 class="text-lg leading-6 font-medium text-gray-900">Commercial Services
                </h4>
                <p class="mt-2 text-base leading-6 text-gray-500">
                  Let us help you decide which roof system is right for your business.
                </p>
              </div>
            </div>
          </li>
        </a>
        <a href='/do-i-need-a-new-roof' class="rounded-md hover:bg-gray-200 p-5">
          <li class="mt-10 md:mt-0">
            <div class="flex">
              <div class="flex-shrink-0">
                <div class="flex items-center justify-center h-12 w-12 rounded-md bg-red-500 text-white">
                  <svg class="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 10V3L4 14h7v7l9-11h-7z"/>
                  </svg>
                </div>
              </div>
              <div class="ml-4">
                <h4 class="text-lg leading-6 font-medium text-gray-900">Do I <em>Need</em> a New Roof?
                </h4>
                <p class="mt-2 text-base leading-6 text-gray-500">
                  Have a look at this helpful infographic to help you make your decision. If you're still unsure, give us a call - our inspections and quotes are always <strong>free</strong>
                </p>
              </div>
            </div>
          </li>
        </a>
        <a href='/service-areas' class="rounded-md hover:bg-gray-200 p-5">
          <li class="mt-10 md:mt-0">
            <div class="flex">
              <div class="flex-shrink-0">
                <div class="flex items-center justify-center h-12 w-12 rounded-md bg-red-500 text-white">
                  <svg class="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M0 0l6 4 8-4 6 4v16l-6-4-8 4-6-4V0zm7 6v11l6-3V3L7 6z"/>
                  </svg>
                </div>
              </div>
              <div class="ml-4">
                <h4 class="text-lg leading-6 font-medium text-gray-900">Areas We Proudly Serve
                </h4>
                <p class="mt-2 text-base leading-6 text-gray-500">
                  We are happy to serve various locations in Montgomery, Liberty, Chambers, and Harris County. Check out this map to see if we're a good fit.
                </p>
              </div>
            </div>
          </li>
        </a>
      </ul>
    </div>
  </div>
</div>
)
export default Services