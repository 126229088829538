import React from "react"

import Img1 from "../images/gallery/1.png"
import Img2 from "../images/gallery/2.png"
import Img3 from "../images/gallery/3.jpg"
import Img4 from "../images/gallery/4.jpg"
import Img5 from "../images/gallery/5.png"
import Img6 from "../images/gallery/6.png"
import Img7 from "../images/gallery/7.png"
import Img8 from "../images/gallery/8.png"
import Img9 from "../images/gallery/9.jpg"

const Gallery = () => {
return (
  <div class="py-12 bg-white" id="gallery">
    <div class="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
      <div class="lg:text-center">
        <p class="text-base leading-6 text-red-600 font-semibold tracking-wide uppercase">Gallery
        </p>
        <h3 class="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
          Some photos of our previous<span class="text-red-600"> work</span>
        </h3>
        <p class="mt-4 max-w-2xl text-xl leading-7 text-gray-500 lg:mx-auto mb-10">
          Take a look around the gallery and see for yourself - we're the best in the business.
        </p> 

        <div class="px-2">
          <div class="flex -mx-2 mb-1">
            <img alt='A job well done by Rayburns Roofing' class="w-1/3 mx-auto mr-1 h-full" src={Img1} />
            <img alt='A job well done by Rayburns Roofing' class="w-1/3 mx-auto mr-1 h-full" src={Img2} />
            <img alt='A job well done by Rayburns Roofing' class="w-1/3 mx-auto mr-1 h-full" src={Img3} />
          </div>
          <div class="flex -mx-2 mb-1">
            <img alt='A job well done by Rayburns Roofing' class="w-1/3 mx-auto mr-1 h-full" src={Img4} />
            <img alt='Follow Rayburns Roofing on Instagram' class="w-1/3 mx-auto mr-1 h-full" src={Img5} />
            <img alt='A job well done by Rayburns Roofing' class="w-1/3 mx-auto mr-1 h-full" src={Img6} />
          </div>
          <div class="flex -mx-2 mb-1">
            <img alt='A job well done by Rayburns Roofing' class="w-1/3 mx-auto mr-1 h-full" src={Img7} />
            <img alt='A job well done by Rayburns Roofing' class="w-1/3 mx-auto mr-1 h-full" src={Img8} />
            <img alt='A job well done by Rayburns Roofing' class="w-1/3 mx-auto mr-1 h-full" src={Img9} />
          </div>
        </div>
      </div>
    </div>
  </div>
)
}

export default Gallery